import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { from, map, tap } from 'rxjs';

export const checkDisclaimerGuard = () => {
  const storage = inject(Storage);
  const router = inject(Router);

  return from(storage.get('ion_did_disclaimer'))
    .pipe(
      tap(diddisclaimer => {
        if (diddisclaimer === true) {
          router.navigate(['/app', 'tabs', 'denunciar']);
        }
      }),
      map(diddisclaimer => !diddisclaimer)
    );
}
