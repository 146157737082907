import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage-angular';
import { Denuncia } from '../../models/denunciaModel';
import { ConfiguracionService } from '../../services/config.service';

@Component({
  selector: 'page-denunciando',
  templateUrl: 'denunciando.html',
  styleUrls: ['./denunciando.scss']
})

export class DenunciandoPage implements OnInit {
  requiredFileType: String;
  questDenuncia: any = {};
  regForm: FormGroup;
  idcentro: String;
  idrelacionprof: String;
  denuncianteanonimo: boolean = false;
  denunciantenombre: String;
  denuncianteapellidos: String;
  denuncianteemail: String;
  denunciantetelefono: String;
  descripcionhechos: String;
  otrosdatos: String;
  primeravez: boolean = false;
  csvinterno: String;
  csvexterno: String;
  denunciaexterna: boolean = false;
  dondeexterna: String;
  IdEmpresa: String;
  fileName: any = [];
  formData: any = [];
  denunciaroute: any;
  denuncia: Denuncia = new Denuncia([]);
  altimg: String;
  files: any[] = [];
  file_coments: any[] = [];
  fileerror: boolean = false;
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private configuracionService: ConfiguracionService,
    private storage: Storage,
    private router: Router,
    private alertController: AlertController
  ) {
    this.ionViewDidEnter();
    this.regForm = this.formBuilder.group({
      idcentro: [this.denuncia.idcentro, [Validators.required]],
      idrelacionprof: [this.denuncia.idrelacionprof, [Validators.required]],
      denuncianteanonimo: [this.denuncia.denuncianteanonimo, [Validators.required, Validators.requiredTrue]],
      denunciantenombre: [this.denuncia.denunciantenombre, [Validators.required, Validators.pattern(/^[a-zA-Z\sªºÁáÉéÍíÓóÚúÜüÑñ]+$/u)]],
      denuncianteapellidos: [this.denuncia.denuncianteapellidos, [Validators.required, Validators.pattern(/^[a-zA-Z\sªºÁáÉéÍíÓóÚúÜüÑñ]+$/u)]],
      denuncianteemail: [this.denuncia.denuncianteemail, [Validators.required, Validators.email]],
      denunciantetelefono: [this.denuncia.denunciantetelefono, [Validators.required, Validators.pattern(/^(\+(?:\d{1,3}))?(\s)?(\d{3}[\s-]?\d{2}[\s-]?\d{2}[\s-]?\d{2})$/)]],
      descripcionhechos: [this.denuncia.descripcionhechos, [Validators.required]],
      otrosdatos: [this.denuncia.otrosdatos, [Validators.required]],
      primeravez: [this.denuncia.primeravez, [Validators.required]],
      csvinterno: [this.denuncia.csvinterno, [Validators.required]],
      csvexterno: [this.denuncia.csvexterno, [Validators.required]],
      denunciaexterna: [this.denuncia.denunciaexterna, [Validators.required]],
      dondeexterna: [this.denuncia.dondeexterna, [Validators.required]]
    });
    const regFormdata = this.regForm.value;
    this.idcentro = regFormdata.idcentro;
    this.primeravez = false;
    this.regForm.get('primeravez').setValue(false);
  }

  ngOnInit() {
    const regFormdata = this.regForm.value;
    this.storage.get("config").then((config) => {
      this.IdEmpresa = config.idempresa;
      this.altimg = config.descripcion;
      this.configuracionService.getCbxCentro(this.IdEmpresa).subscribe((cbx_centro) => { this.storage.set("cbx_centro", cbx_centro); });
      this.storage.get("cbx_centro").then((data) => { this.questDenuncia.cbxCentro = data; });
      this.configuracionService.getCbxRelacionProf(this.IdEmpresa).subscribe((cbxRelacionProf) => { this.storage.set("cbxRelacionProf", cbxRelacionProf); });
      this.storage.get("cbxRelacionProf").then((data) => { this.questDenuncia.cbxRelacionProf = data; });
      this.idcentro = regFormdata.idcentro;
    });
    this.denuncianteanonimo = regFormdata.denuncianteanonimo;
  }

  async onDemand() {
    this.isLoading = true;
    try {
      const regFormdata = this.regForm.value;
      this.denuncia.idempresa = this.IdEmpresa;
      this.denuncia.idcentro = regFormdata.idcentro;
      this.denuncia.idrelacionprof = regFormdata.idrelacionprof;
      this.denuncia.denuncianteanonimo = regFormdata.denuncianteanonimo;
      this.denuncia.denunciantenombre = regFormdata.denunciantenombre;
      this.denuncia.denuncianteapellidos = regFormdata.denunciantenombre;
      this.denuncia.denuncianteemail = regFormdata.denuncianteemail;
      this.denuncia.denunciantetelefono = regFormdata.denunciantetelefono;
      this.denuncia.descripcionhechos = regFormdata.descripcionhechos;
      this.denuncia.otrosdatos = regFormdata.otrosdatos;
      this.denuncia.primeravez = !regFormdata.primeravez;
      this.denuncia.csvinterno = regFormdata.csvinterno;
      this.denuncia.csvexterno = regFormdata.csvexterno;
      this.denuncia.denunciaexterna = regFormdata.denunciaexterna;
      this.denuncia.dondeexterna = regFormdata.dondeexterna;

      if (!regFormdata.denunciaexterna || this.denuncia.denunciaexterna == null) this.denuncia.denunciaexterna = false;
      if (!this.denuncia.denuncianteanonimo || this.denuncia.denuncianteanonimo == null) this.denuncia.denuncianteanonimo = false;

      const hashDenuncia = await this.configuracionService.addDenuncia(this.denuncia, this.formData, this.file_coments)
        .then((data) => { return data; });

      this.presentPopover(hashDenuncia.hash, hashDenuncia.fechaAlta, this.denuncia)
        .then(() => { this.denuncia = null; })
        .catch((error) => { this.showAlert('Error intentelo nuevamente', error); });

    } catch (error) {
      // Manejo de errores si es necesario
    } finally {
      this.isLoading = false;
    }
  }

  private async showAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ['OK']
    });
    await alert.present();
  }

  onCbxAnonimoChange() {
    const hideFields = this.regForm.get('denuncianteanonimo').value;
    this.setFieldVisibility('denunciantenombre', hideFields);
    this.setFieldVisibility('denuncianteapellidos', hideFields);
    this.setFieldVisibility('denuncianteemail', hideFields);
    this.setFieldVisibility('denunciantetelefono', hideFields);
  }

  onCbxPvezChange() {
    const hideFields = !this.regForm.get('primeravez').value;
    this.setFieldVisibility('csvinterno', hideFields);
  }

  onCbxExternaChange() {
    const hideFields = !this.regForm.get('denunciaexterna').value;
    this.setFieldVisibility('csvexterno', hideFields);
  }

  checkButton() {
    let showButton = true;
    showButton = showButton && !this.regForm.controls['idcentro'].errors?.['required'];
    showButton = showButton && !this.regForm.controls['idrelacionprof'].errors?.['required'];
    if (this.regForm.get('denuncianteanonimo').value != true) {
      this.regForm.get('denuncianteanonimo').setValue(false);
    }
    showButton = showButton && !this.regForm.get('descripcionhechos').hasError('required');
    if (this.regForm.get('primeravez').value == true) {
      showButton = showButton && !this.regForm.get('csvinterno').hasError('required');
    }
    if (this.regForm.get('denunciaexterna').value == true) {
      showButton = showButton && !this.regForm.get('dondeexterna').hasError('required');
    }
    showButton = showButton && !this.fileerror;
    return showButton;
  }

  private setFieldVisibility(fieldName: string, hide: boolean) {
    const control = this.regForm.get(fieldName);
    if (control) {
      if (hide) { control.disable(); }
      else { control.enable(); }
    }
  }

  onChangeComent(index: number, event: any) {
    this.file_coments[index] = event.detail.value;
  }

  onFileSelected(event: any) {
    const maxTotalFileSizeInBytes = 20 * 1024 * 1024;
    const selectedFiles = Array.from(event.target.files) as File[];

    // Añadir solo archivos válidos sin sobrescribir los comentarios existentes
    selectedFiles.forEach(file => {
      if (file) {
        // Verificar si el archivo ya existe en la lista
        const existingFileIndex = this.files.findIndex(f => f.name === file.name);

        if (existingFileIndex === -1) {
          // Solo agregar el archivo si no está ya presente
          this.files.push(file);
          const controlName = "file_coment_" + (this.files.length - 1);
          this.regForm.addControl(controlName, this.formBuilder.control(null));
        }
      }
    });

    // Recalcular el tamaño total
    let totalFileSize = this.files.reduce((acc, file) => acc + (file ? file.size : 0), 0);
    const fileSizeInfo = document.querySelector('.file-size-info') as HTMLElement;

    if (totalFileSize > maxTotalFileSizeInBytes) {
      fileSizeInfo.textContent = 'El tamaño total de los archivos excede el límite permitido (20 MB).';
      fileSizeInfo.style.color = 'red';
    } else {
      fileSizeInfo.textContent = 'Tamaño máximo total: 20 MB';
      fileSizeInfo.style.color = '#757575';
    }

    // Preservar los comentarios anteriores
    for (let index = 0; index < this.files.length; index++) {
      const file: File = this.files[index];
      const control = this.regForm.get("file_coment_" + index);

      if (control) {
        // Mantener el comentario si ya existía
        this.file_coments[index] = this.file_coments[index] || control.value;
      }

      if (file) {
        this.fileName[index] = file.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const archivoModificado = new File([file], this.fileName[index], {
            type: file.type,
            lastModified: file.lastModified
        });
        this.formData[index] = new FormData();
        this.formData[index].append("thumbnail", archivoModificado);
      }
    }
  }

  eliminarFile(index: number) {
    // Elimina el archivo y el comentario en las posiciones correspondientes
    if (index >= 0 && index < this.files.length) {
      this.files.splice(index, 1);
      this.file_coments.splice(index, 1);
      // Ajustar los comentarios para que coincidan con los índices actuales
      this.file_coments = this.file_coments.filter((comment, i) => i < this.files.length);
      const fileSizeInfo = document.querySelector('.file-size-info') as HTMLElement;
      // Calcular el tamaño total solo para archivos válidos
      let totalFileSize = this.files.reduce((acc, file) => {
        return acc + (file && file.size ? file.size : 0);
      }, 0);
      if (totalFileSize > 20 * 1024 * 1024) {
        fileSizeInfo.textContent = 'El tamaño total de los archivos excede el límite permitido (20 MB).';
        fileSizeInfo.style.color = 'red';
      } else {
        fileSizeInfo.textContent = 'Tamaño máximo total: 20 MB';
        fileSizeInfo.style.color = '#757575';
      }
      // Actualiza la forma para reflejar los cambios
      this.regForm.patchValue(this.regForm.value);
    }
  }

  triggerFileInput() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) { fileInput.click(); }
  }

  async ionViewDidEnter() {
    const config = await this.storage.get("config");
    this.IdEmpresa = config.idempresa;
    this.altimg = config.descripcion;
    this.configuracionService.getCbxCentro(this.IdEmpresa).subscribe((cbx_centro) => { this.storage.set("cbx_centro", cbx_centro); });
    this.storage.get("cbx_centro").then((data) => { this.questDenuncia.cbxCentro = data; });
    this.configuracionService.getCbxRelacionProf(this.IdEmpresa).subscribe((cbxRelacionProf) => { this.storage.set("cbxRelacionProf", cbxRelacionProf); });
    this.storage.get("cbxRelacionProf").then((data) => { this.questDenuncia.cbxRelacionProf = data; });
  }

  async presentPopover(hashDenuncia: string, fechaalta: string, denuncia: any) {
    this.router.navigateByUrl(`/pdf-popover/${hashDenuncia}?fechaalta=${fechaalta}&denuncia=${encodeURIComponent(JSON.stringify(denuncia))}`);
  }
}
