<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-list-header>
      <h1>Canal de Denuncias</h1>
    </ion-list-header>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="1"></ion-col>
      <ion-col size="10">
        <form [formGroup]="regForm">
          <ion-list>
            <ion-item>
              <ion-select labelPlacement="stacked" label="Centro al que dirige la denuncia:" formControlName="idcentro"
                required>
                <div slot="label"><ion-text color="danger">(Requerido)</ion-text></div>
                <ion-select-option *ngFor="let centro of questDenuncia.cbxCentro" [value]="centro.idcentro">{{
                  centro.Descripcion }}</ion-select-option>
              </ion-select>
              <ion-label color="danger"
                *ngIf="regForm.controls['idcentro'].errors?.['required'] && (regForm.controls['idcentro'].touched || regForm.controls['idcentro'].dirty)">*
                El Centro es obligatorio</ion-label>
            </ion-item>

            <ion-item>
              <ion-select labelPlacement="stacked" label="Relación profesional con la entidad:"
                formControlName="idrelacionprof" required>
                <div slot="label"><ion-text color="danger">(Requerido)</ion-text></div>
                <ion-select-option *ngFor="let relacion of questDenuncia.cbxRelacionProf"
                  [value]="relacion.idrelacionprof">{{ relacion.Descripcion }}</ion-select-option>
              </ion-select>
              <ion-label color="danger"
                *ngIf="regForm.controls['idrelacionprof'].errors?.['required'] && (regForm.controls['idrelacionprof'].touched || regForm.controls['idrelacionprof'].dirty)">*
                La Relación Profesional es obligatoria</ion-label>
            </ion-item>

            <ion-item>
              <ion-checkbox formControlName="denuncianteanonimo" (ionChange)="onCbxAnonimoChange()"
                justify="start"></ion-checkbox>
              <ion-label><span>&nbsp;</span>Marcar si desea que su denuncia sea anónima</ion-label>
            </ion-item>

            <ion-item *ngIf="!regForm.controls['denuncianteanonimo'].value">
              <ion-input labelPlacement="stacked" label="Nombre:" formControlName="denunciantenombre"
                type="text"></ion-input>
              <ion-label color="danger" *ngIf="regForm.controls['denunciantenombre'].errors?.['pattern']">* El Nombre no
                cumple el formato</ion-label>
            </ion-item>

            <ion-item *ngIf="!regForm.controls['denuncianteanonimo'].value">
              <ion-input labelPlacement="stacked" label="Apellidos:" formControlName="denunciantenombre"
                type="text"></ion-input>
              <ion-label color="danger" *ngIf="regForm.controls['denunciantenombre'].errors?.['pattern']">* El Apellido
                no cumple el formato</ion-label>
            </ion-item>

            <ion-item *ngIf="!regForm.controls['denuncianteanonimo'].value">
              <ion-input labelPlacement="stacked" label="Correo electrónico:" formControlName="denuncianteemail"
                type="email"></ion-input>
              <ion-label color="danger" *ngIf="regForm.controls['denuncianteemail'].errors?.['email']">* El Correo
                electrónico no cumple el formato</ion-label>
            </ion-item>

            <ion-item *ngIf="!regForm.controls['denuncianteanonimo'].value">
              <ion-input labelPlacement="stacked" label="Teléfono:" formControlName="denunciantetelefono"
                type="tel"></ion-input>
              <ion-label color="danger" *ngIf="regForm.controls['denunciantetelefono'].errors?.['pattern']">* El
                Teléfono no cumple el formato</ion-label>
            </ion-item>

            <ion-item>
              <ion-textarea labelPlacement="stacked" label="Descripción de los hechos:"
                formControlName="descripcionhechos" required></ion-textarea>
              <ion-label color="danger"
                *ngIf="regForm.controls['descripcionhechos'].errors?.['required'] && (regForm.controls['descripcionhechos'].touched || regForm.controls['descripcionhechos'].dirty)">*
                La descripción de los hechos es obligatoria</ion-label>
            </ion-item>

            <ion-item>
              <ion-textarea labelPlacement="stacked" label="Otros datos:" formControlName="otrosdatos"></ion-textarea>
            </ion-item>

            <ion-item>
              <ion-checkbox formControlName="primeravez" (ionChange)="onCbxPvezChange()" justify="start"></ion-checkbox>
              <ion-label><span>&nbsp;</span>Denunciado antes en este Canal.</ion-label>
            </ion-item>

            <ion-item *ngIf="regForm.controls['primeravez'].value">
              <ion-input labelPlacement="stacked" label="Código de referencia anterior:" formControlName="csvinterno"
                type="text" required></ion-input>
              <ion-label color="danger"
                *ngIf="regForm.controls['csvinterno'].errors?.['required'] && (regForm.controls['csvinterno'].touched || regForm.controls['csvinterno'].dirty)">*
                El Código de referencia anterior es obligatorio</ion-label>
            </ion-item>

            <ion-item>
              <ion-checkbox formControlName="denunciaexterna" (ionChange)="onCbxExternaChange()"
                justify="start"></ion-checkbox>
              <ion-label><span>&nbsp;</span>Denuncia externa</ion-label>
            </ion-item>

            <ion-item *ngIf="regForm.controls['denunciaexterna'].value">
              <ion-input labelPlacement="stacked" label="Número de denuncia:" formControlName="csvexterno"
                type="text"></ion-input>
            </ion-item>

            <ion-item *ngIf="regForm.controls['denunciaexterna'].value">
              <ion-input labelPlacement="stacked" label="Sitio de denuncia" formControlName="dondeexterna"
                required></ion-input>
              <ion-label color="danger"
                *ngIf="regForm.controls['dondeexterna'].errors?.['required'] && (regForm.controls['dondeexterna'].touched || regForm.controls['dondeexterna'].dirty)">*
                El sitio de denuncia es obligatorio.</ion-label>
            </ion-item>
            <ion-col size-xs="12" size-sm="12" size-md="9" size-lg="9">
              <ion-label stacked>
                <ion-icon name="document"></ion-icon> Adjuntar Documentos
                <span class="file-size-info">Tamaño máximo total: 20 MB</span>
              </ion-label>
              <br>
              <input type="file" multiple name="file" id="fileInput" [accept]="requiredFileType"
                (change)="onFileSelected($event)" hidden>
              <ion-button (click)="triggerFileInput()">Añadir Archivos</ion-button>
              <ng-container *ngFor="let file of files; let i = index">
                <ion-card>
                  <ion-card-header>
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                      <ion-card-title>
                        {{ files[i]?.name }}
                      </ion-card-title>
                      <ion-button (click)="eliminarFile(i)" fill="clear" class="floating-button">
                        <ion-icon size="large" style="color: red;" name="close-circle"></ion-icon>
                      </ion-button>
                    </div>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-item>
                      <ion-input type="text" [value]="file_coments[i]" (input)="file_coments[i] = $event.target.value"
                        placeholder="Describe el documento">
                      </ion-input>
                    </ion-item>
                  </ion-card-content>
                </ion-card>
              </ng-container>
            </ion-col>
          </ion-list>
          <ion-row responsive-sm>
            <ion-col>
              <ion-button
                expand="full"
                *ngIf="checkButton()"
                (click)="onDemand()"
                color="primary"
                class="ion-margin-top"
                [disabled]="isLoading" >
                <ng-container *ngIf="!isLoading; else loadingSpinner">
                Enviar Denuncia
                </ng-container>
              </ion-button>
                <ng-template #loadingSpinner>
                  <ion-spinner name="crescent"></ion-spinner>
                </ng-template>
            </ion-col>
          </ion-row>
        </form>
      </ion-col>
      <ion-col size="1"></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
