<ion-header>
  <ion-toolbar>
    <h1 class="ion-padding-start ion-text-center">Canal de Denuncias.</h1>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="1"></ion-col>
      <ion-col size="10">
        <ion-list lines="none">
          <ion-item>
            <ion-label class="ion-text-justify">
              <ion-icon name="chevron-forward-outline"></ion-icon>
              En este canal se pueden presentar denuncias relacionadas con infracciones del derecho europeo, acciones u
              omisiones que puedan constituir una infracción penal o administrativa.</ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-justify">
              <ion-icon name="chevron-forward-outline"></ion-icon>
              El canal está a disposición de los trabajadores, ex trabajadores, personal en prácticas, personal de ETT,
              voluntarios, candidatos a un puesto de trabajo, personal de empresas subcontratadas, autónomos y cualquier
              persona que trabaje para la organización o bajo la supervisión de contratistas, subcontratistas y proveedores.
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-justify">
              <ion-icon name="chevron-forward-outline"></ion-icon>
              Puede identificarse al presentar su denuncia o hacerlo anónimamente.
              En caso de disponer de la identificación del denunciante, esta información no se revelará al denunciado en ningún caso.
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-justify">
              <ion-icon name="chevron-forward-outline"></ion-icon>
              Este canal no es la vía adecuada para reclamaciones de clientes.
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-justify">
              <ion-icon name="chevron-forward-outline"></ion-icon>
              Para todas las comunicaciones remitidas a través del Canal de Denuncias, el informante tendrá la posibilidad de
              conocer el estado de la comunicación a través de un 'código de referencia' que le será asignado al registrar la denuncia.
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-justify">
              <ion-icon name="chevron-forward-outline"></ion-icon>
              <em>El 'código de referencia' solo le será mostrado en el mismo momento del envío de la comunicación. Es importante
              mantener el 'código de referencia', ya que, en caso contrario, no podrá conocer el estado de la comunicación
              presentada.</em>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-justify">
              <ion-icon name="chevron-forward-outline"></ion-icon>
              <em>Si es imprescindible ampliar la información para el inicio de las tareas de investigación, se mostrará un mensaje en el Canal de Denuncias.</em>
            </ion-label>
          </ion-item>
          <ion-item>
            <br>
            <div class="table-wrapper">
              <table>
                <caption><h2>Información sobre protección de datos personales</h2></caption>
                <tr>
                  <th>RESPONSABLE</th>
                  <td>
                    {{ altimg }}<br>
                    Delegado de Protección de Datos dpd@contamicro.com
                  </td>
                </tr>
                <tr>
                  <th>FINALIDAD</th>
                  <td>Gestionar la información recibida en el Canal de Denuncias.</td>
                </tr>
                <tr>
                  <th>LEGITIMACIÓN</th>
                  <td>
                    Cumplimiento de una obligación legal.
                    Articulo 30.2 de la Ley 2/2023, de 20 de febrero,<br>
                    reguladora de la protección de las personas que
                    informen sobre infracciones normativas y de lucha contra la corrupción.
                  </td>
                </tr>
                <tr>
                  <th>DESTINATARIOS DE CESIONES</th>
                  <td>{{ (IdEmpresa == "1")?'No están previstas.':'Empresa de gestión del Canal de Denuncias.' }}</td>
                </tr>
                <tr>
                  <th>TRANSFERENCIAS INTERNACIONALES</th>
                  <td>No están previstas.</td>
                </tr>
                <tr>
                  <th>DERECHOS</th>
                  <td>
                    Acceso, rectificación, supresión, limitación del tratamiento, oposición y/o
                    portabilidad de tus datos dirigiéndose por escrito al Responsable o a su Delegado de Protección de Datos.
                  </td>
                </tr>
                <tr>
                  <th>INFORMACIÓN ADICIONAL</th>
                  <td>
                    Puede solicitar información adicional y detallada sobre Protección de Datos a través del correo electrónico dpd@contamicro.com
                  </td>
                </tr>
              </table>
            </div>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid class="ion-padding">
    <ion-row class="ion-padding">
      <ion-col></ion-col>
      <ion-col>
        <ion-checkbox name="heleido" [(ngModel)]="heleido" justify="end" style="margin-right: 15px;">He leído y acepto los términos.</ion-checkbox>
        <ion-button color="secondary" [hidden]="false" (click)="endApp()" style="min-width: 125px">Cancelar</ion-button>
        <ion-button color="primary" [hidden]="false" (click)="startApp()" style="min-width: 125px" [disabled]="onCbxLeido()" >Continuar</ion-button>
      </ion-col>
      <ion-col></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
