import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { checkDisclaimerGuard } from './providers/check-disclaimer.guard';
import { PdfPopoverPage } from './pages/pdf-popover/pdf-popover';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/disclaimer/disclaimer.module').then(m => m.DisclaimerModule),
    canMatch: [checkDisclaimerGuard]
  },
  {
    path: 'app/tabs/denunciar',
    loadChildren: () => import('./pages/denunciar/denunciar.module').then(m => m.DenunciarModule)
  },
  {
    path: 'app/tabs/denunciando',
    loadChildren: () => import('./pages/denunciando/denunciando.module').then(m => m.DenunciandoModule)
  },
  {
    path: 'app/tabs/seguimiento',
    loadChildren: () => import('./pages/seguimiento/seguimiento.module').then(m => m.SeguimientoModule)
  },
  {
    path: 'app/tabs/seguimiento/:csv',
    loadChildren: () => import('./pages/seguimiento/seguimiento.module').then(m => m.SeguimientoModule)
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('./pages/disclaimer/disclaimer.module').then(m => m.DisclaimerModule),
    canMatch: [checkDisclaimerGuard]
  },
  {
    path: 'app/tabs/about',
    loadChildren: () => import('./pages/disclaimer/disclaimer.module').then(m => m.DisclaimerModule),
    canMatch: [checkDisclaimerGuard]
  },
  {
    path: 'pdf-popover/:hashDenuncia',
    component: PdfPopoverPage
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
