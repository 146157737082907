import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { SeguimientoPage } from './seguimiento';
import { OrderByTimeChangePipe } from '../../order-by-time-change.pipe';
import { SeguimientoPageRoutingModule } from './seguimiento-routing.module';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IonicModule,
    SeguimientoPageRoutingModule
  ],
  declarations: [
    SeguimientoPage,
    OrderByTimeChangePipe
  ]
})
export class SeguimientoModule { }
