import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByTimeChange'
})
export class OrderByTimeChangePipe implements PipeTransform {

  transform(sessions: any[], field: string): any[] {
    return sessions.sort((a, b) => b[field] - a[field]);
  }

}
