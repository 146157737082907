import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { DisclaimerPage } from './disclaimer';
import { DisclaimerPageRoutingModule } from './disclaimer-routing.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        DisclaimerPageRoutingModule,
    ],
    declarations: [DisclaimerPage]
})
export class DisclaimerModule {}
