import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { MenuController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { ConfiguracionService } from '../../services/config.service';

@Component({
  selector: 'page-disclaimer',
  templateUrl: 'disclaimer.html',
  styleUrls: ['./disclaimer.scss'],
})
export class DisclaimerPage {
  showSkip = true;
  heleido = false;
  altimg: String;
  IdEmpresa: String;

  constructor(public menu: MenuController, public router: Router, private configuracionService: ConfiguracionService, private storage: Storage) {}

  onCbxLeido(){
    return !this.heleido;
  }

  startApp() {
    this.router
      .navigateByUrl('/app/tabs/denunciar', { replaceUrl: true })
      .then(() => this.storage.set('ion_did_disclaimer', false));
  }

  endApp() {
    this.storage.set('ion_did_disclaimer', false);
    window.location.href = 'https://www.herandes.es';
  }

  ionViewWillEnter() {
    this.storage.get('ion_did_disclaimer').then(res => {
      if (res === true) {
        this.router.navigateByUrl('/app/tabs/denunciar', { replaceUrl: true });
      }
    });
    this.menu.enable(false);
  }

  ionViewDidLeave() {
    this.menu.enable(true);
  }

  async ionViewDidEnter() {
    const config = await this.storage.get("config");
    this.IdEmpresa = config.idempresa;
    this.altimg = config.descripcion;
    this.configuracionService.getCbxCentro(this.IdEmpresa).subscribe((cbx_centro) => { this.storage.set("cbx_centro", cbx_centro); });
    this.configuracionService.getCbxRelacionProf(this.IdEmpresa).subscribe((cbxRelacionProf) => { this.storage.set("cbxRelacionProf", cbxRelacionProf); });
  }
}
