<ion-app [class.dark-theme]="dark">
  <ion-split-pane contentId="main-content">

    <ion-menu contentId="main-content">
      <ion-content>
        <ion-list lines="none">
          <ion-list-header>
            <h1>Menú</h1>
          </ion-list-header>
          <ion-menu-toggle class="container" autoHide="false" *ngFor="let p of appPages; let i = index">
            <ion-item class="link" [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
              <ion-icon slot="start" [name]="p.icon + '-outline'"></ion-icon>
              <ion-label class="text">
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle autoHide="false">
            <ion-item [hidden]="true">
              <ion-icon slot="start" name="moon-outline"></ion-icon>
              <ion-toggle [(ngModel)]="dark" (ionChange)="setDarkMode()">
                Modo Nocturno
              </ion-toggle>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
