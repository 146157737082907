import { Model } from './models';

export class Denuncia extends Model {
  idempresa:String;
  idcentro:String;
  idrelacionprof:String;
  denuncianteanonimo:boolean;
  denunciantenombre:String;
  denuncianteapellidos:String;
  denuncianteemail:String;
  denunciantetelefono:String;
  descripcionhechos:String;
  otrosDatos:String;
  primeravez:boolean;
  csvinterno:String;
  csvexterno:String;
  denunciaexterna:boolean;
  dondedenuncia:String;

  override attributeNames: string[] = [
    'idempresa', 'idcentro', 'idrelacionprof', 'denuncianteanonimo',
    'denunciantenombre', 'denuncianteapellidos','denuncianteemail',
    'denunciantetelefono', 'descripcionhechos', 'otrosDatos',
    'primeravez', 'csvinterno', 'csvexterno', 'denunciaexterna', 'dondedenuncia'
  ];
}
