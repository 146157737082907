<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-list-header>
      <h1>Canal de Denuncias</h1>
    </ion-list-header>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen="true">
  <ion-item>
    <ion-label style="text-align: center;" *ngIf="csv">
      <h1>Denuncia con CSV: {{ csv }}</h1>
    </ion-label>
  </ion-item>
  <ion-list #seguimientoList>
    <ion-item-group *ngFor="let group of groups">
      <ion-item-sliding *ngFor="let session of group.sessions | orderByTimeChange:'timeChange'">
        <ion-item>
          <ion-label>
            <h3>{{session.name}}<span *ngIf="session.id == 2"> :{{ session.detalle }}</span> </h3>
            <p>
              {{ group.time }}  {{ session.timeChange }}
            </p>
            <ion-item *ngIf="getLastState(groups) == session.id && session.id == 2">
              <ion-col size-xs="12" size-sm="12" size-md="9" size-lg="9">
              <form [formGroup]="regForm">
                <ion-label stacked>
                  <ion-icon name="document"></ion-icon> Adjuntar Documentos
                  <span class="file-size-info">Tamaño máximo total: 20 MB</span>
                </ion-label>
                <br>
                <input type="file" multiple name="file" id="fileInput" [accept]="requiredFileType"
                  (change)="onFileSelected($event)" hidden>
                <ion-button (click)="triggerFileInput()">Añadir Archivos</ion-button>
                <ng-container *ngFor="let file of files; let i = index">
                  <ion-card>
                    <ion-card-header>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <ion-card-title>
                          {{ files[i]?.name }}
                        </ion-card-title>
                        <ion-button (click)="eliminarFile(i)" fill="clear" class="floating-button">
                          <ion-icon size="large" style="color: red;" name="close-circle"></ion-icon>
                        </ion-button>
                      </div>
                    </ion-card-header>
                    <ion-card-content>
                      <ion-item>
                        <ion-input type="text" [value]="file_coments[i]" (input)="file_coments[i] = $event.target.value"
                          placeholder="Describe el documento">
                        </ion-input>
                      </ion-item>
                    </ion-card-content>
                  </ion-card>
                </ng-container>
              </form>
              <ion-label
                color="danger"
                *ngIf="uploaderror"
              >* Error al subir los ficheros reintentelo. </ion-label>
              </ion-col>
              <ion-col size-xs="12" size-sm="12" size-md="3" size-lg="3">
                <ion-button
                  *ngIf="files.length > 0"
                  expand="full"
                  (click)="onNewDocument()"
                  color="primary"
                  block
                  [disabled]="isLoading"
                ><ng-container *ngIf="!isLoading; else loadingSpinner">
                  Subir Archivos
                </ng-container></ion-button>
                <ng-template #loadingSpinner>
                  <ion-spinner name="crescent"></ion-spinner>
                </ng-template>
              </ion-col>
            </ion-item>
          </ion-label>
        </ion-item>
      </ion-item-sliding>
    </ion-item-group>
  </ion-list>
</ion-content>
