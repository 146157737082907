import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { MenuController, Platform, ToastController } from '@ionic/angular';

import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';

import { UserData } from './providers/user-data';

import { DarkModeService } from './services/dark-mode.service';
import { ConfiguracionService } from './services/config.service';
import { Storage } from '@ionic/storage-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loggedIn = false;
  dark = this.darkModeService.isDarkModeEnabled();
  hashEmpresa: string;
  lastcsv: string = '';
  appPages = [
    {
      title: 'Inicio',
      url: '/app/tabs/denunciar',
      icon: 'home'
    },
    {
      title: 'Denunciar',
      url: '/app/tabs/denunciando',
      icon: 'warning'
    },
    {
      title: 'Seguimiento',
      url: '/app/tabs/seguimiento',
      icon: 'map'
    },
    {
      title: 'Acerca de',
      url: '/app/tabs/about',
      icon: 'information-circle'
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private darkModeService: DarkModeService,
    private configuracionService: ConfiguracionService,
    private storage: Storage
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    await this.storage.create();
    this.route.queryParams.subscribe(params => {
      this.hashEmpresa = params['hashEmpresa'];
      if (this.hashEmpresa) {
        this.storage.set('hashEmpresaValue', this.hashEmpresa).then(() => {
          console.log('hashEmpresa:', this.hashEmpresa);
        });
        this.configuracionService.getConfiguracion(this.hashEmpresa).subscribe((configuracion) => {
          this.storage.set("config", configuracion);
        });
      }
    });
    this.checkLoginStatus();
    this.listenForLoginEvents();

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid')) {
        StatusBar.hide();
        SplashScreen.hide();
      }
    });
  }

  setDarkMode(){
    if (this.dark) {
      this.darkModeService.enableDarkMode();
    } else {
      this.darkModeService.disableDarkMode();
    }
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/app/tabs/denunciar');
    });
  }

  opendisclaimer() {
    this.menu.enable(false);
    this.storage.set('ion_did_disclaimer', false);
    this.router.navigateByUrl('/disclaimer');
  }
}
