import { Component, AfterViewInit, OnInit  } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as QRCode from 'qrcode';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-pdf-popover',
  templateUrl: 'pdf-popover.html',
  styleUrls: ['./pdf-popover.scss'],
})
export class PdfPopoverPage implements AfterViewInit, OnInit {
  hashDenuncia: string;
  denuncia: any = {};
  qrCodeImageUrl: string;
  qrCodeImageUrlAnt: string;
  logoimg: string;
  empresa: string;
  centro: string;
  fechaAlta: string;
  relacionprof: string;
  base64Image: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private storage: Storage,
    private router: Router
    ) {}

  ngOnInit() {
    this.hashDenuncia = this.route.snapshot.paramMap.get('hashDenuncia');
    this.route.queryParams.subscribe(params => {
      if (params.denuncia) {
        this.denuncia = JSON.parse(decodeURIComponent(params.denuncia));
        this.fechaAlta = params.fechaalta;
      }
    });
  }

  ngAfterViewInit() {
    this.generateQRCode();
  }

  fechahora() {
    let fechaActual = new Date(this.fechaAlta);
    let año = fechaActual.getFullYear();
    let mes = fechaActual.getMonth() + 1;
    let dia = fechaActual.getDate();
    let horas = fechaActual.getHours();
    let minutos = fechaActual.getMinutes();
    let segundos = fechaActual.getSeconds();
    let fechaHoraActual = dia.toString().padStart(2, '0') + '/' + mes.toString().padStart(2, '0') + '/' + año + ' '
                        + horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0') + ':' + segundos.toString().padStart(2, '0');
    return fechaHoraActual;
  }

  loadAndConvertImage(): void {
    this.http.get(this.logoimg, { responseType: 'blob' })
      .subscribe((blob: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.base64Image = reader.result as string;
        };
        reader.readAsDataURL(blob);
      });
  }

  async generateReport() {
    try {
      this.generatePDF();
      this.router.navigateByUrl('/app/tabs/denunciar');
    } catch (error) {
      console.error('Error al generar el PDF:', error);
    }
  }

  private async generateQRCode() {
    const config = await this.storage.get("config");
    this.logoimg = config.logo;
    this.empresa = config.descripcion;
    this.loadAndConvertImage();
    this.storage.get("cbx_centro").then((data) => {
      const itemEncontrado = data.find(item => item.idcentro === this.denuncia.idcentro);
      this.centro = itemEncontrado.Descripcion;
    });
    this.storage.get("cbxRelacionProf").then((data) => {
      const itemEncontrado = data.find(item => item.idrelacionprof === this.denuncia.idrelacionprof);
      this.relacionprof = itemEncontrado.Descripcion;
    });
    const hashEmpresa = await this.storage.get("hashEmpresaValue");
    QRCode.toDataURL(`https://www.canaldedenunciactmc.com/app/tabs/seguimiento/${this.hashDenuncia.split('-')[0]}?hashEmpresa=${hashEmpresa}`)
    .then(url => {
      this.qrCodeImageUrl = url;
      QRCode.toDataURL(`https://www.canaldedenunciactmc.com/app/tabs/seguimiento/${this.denuncia.csvinterno}?hashEmpresa=${hashEmpresa}`)
      .then(url2 => {
        this.qrCodeImageUrlAnt = url2;
      });
    })
    .catch(err => {
      console.error(err)
    });
  }

  private generatePDF() {
    const pdfOptions = {
      orientation: 'portrait',
    };
    const elementPDF = document.getElementById('elemPDF');
    html2pdf(elementPDF).set(pdfOptions);
  }
}
