import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  private darkModeKey = 'darkModeEnabled';

  isDarkModeEnabled(): boolean {
    const darkMode = localStorage.getItem(this.darkModeKey);
    return darkMode ? JSON.parse(darkMode) : false;
  }

  enableDarkMode(): void {
    localStorage.setItem(this.darkModeKey, 'true');
  }

  disableDarkMode(): void {
    localStorage.setItem(this.darkModeKey, 'false');
  }
}
