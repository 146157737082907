import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { DenunciandoPage } from './denunciando';
import { DenunciandoPageRoutingModule } from './denunciando-routing.module';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IonicModule,
    DenunciandoPageRoutingModule
  ],
  declarations: [
    DenunciandoPage,
  ]
})
export class DenunciandoModule { }
