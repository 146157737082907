<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-list-header>
      <h1>Canal de Denuncias</h1>
    </ion-list-header>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="denunciar-logo">
    <img src={{logoimg}} alt={{altimg}}>
  </div>
  <div class="denunciar-form">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="1" size-lg="1"></ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="4" size-lg="4">
          <form class="ion-padding ion-text-center" #submitForm="ngForm" novalidate (ngSubmit)="denunciar()">
            <ion-label>El Buzón es un canal seguro de comunicación electrónica bidireccional que garantiza la confidencialidad y permite el anonimato de las informaciones.</ion-label>
            <div class="ion-padding">
              <ion-button color="danger" expand="block" type="submit">REALIZAR DENUNCIA</ion-button>
            </div>
          </form>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="1" size-lg="1"></ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="1" size-lg="1" class="separator"></ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="4" size-lg="4">
          <form class="ion-padding ion-text-center" #submitForm="ngForm" novalidate (ngSubmit)="seguirdenuncia()">
            <ion-label>¿Ya ha presentado una denuncia?</ion-label>
            <br><br>
            <ion-input
              label="Introduzca su Código de referencia."
              labelPlacement="stacked"
              fill="solid"
              rows="1"
              [(ngModel)]="denunciacsv"
              name="denunciarQuestion"
              #denunciarQuestion="ngModel"
              errorText="El Código de referencia es requerido."
              required>
            </ion-input>
            <div class="ion-padding">
              <ion-button expand="block" type="submit">Ver Seguimiento</ion-button>
            </div>
          </form>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="1" size-lg="1"></ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar class="ion-text-center">
    <ion-label>Canal de Denuncias</ion-label>
  </ion-toolbar>
</ion-footer>
