import { Component } from '@angular/core';
import { Router  } from '@angular/router';
import { Storage } from '@ionic/storage-angular';

import { AlertController, ToastController } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';


@Component({
  selector: 'page-denunciar',
  templateUrl: './denunciar.html',
  styleUrls: ['./denunciar.scss']
})
export class DenunciarPage {
  denunciar_submitted = false;
  seguimiento_submitted = false;
  seguimientoMessage: string;
  denunciacsv:string;
  logoimg: string;
  altimg: string;

  constructor(
    public alertCtrl: AlertController,
    private router: Router,
    public toastCtrl: ToastController,
    public popoverCtrl: PopoverController,
    private storage: Storage
  ) { }

  async ionViewDidEnter() {
    const config = await this.storage.get("config");
    this.logoimg = config.logo;
    this.altimg = config.descripcion;
  }

  async denunciar() {
    this.router.navigateByUrl('/app/tabs/denunciando');
  }

  async seguirdenuncia() {
    this.storage.set("lastcsv", this.denunciacsv);
    this.router.navigateByUrl(`/app/tabs/seguimiento/${this.denunciacsv}`);
  }
}
