import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Denuncia } from "../models/denunciaModel";

@Injectable({
  providedIn: 'root',
})
export class ConfiguracionService {
  private apiUrl = 'https://erp.altho.es:8443/gasws320/ws/r/ctmws/0002024/wsservice/';
  //private apiUrl = 'http://192.168.10.15:8181/wsservice/';
  uploadSub:any;
  uploadProgress:any;
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true'
   }),
  };

  constructor(private http: HttpClient) {}

  getConfiguracion(hashEmpresa:string): Observable<any> {
    return this.http.get(`${this.apiUrl}get/EmpresaData?${hashEmpresa}`);
  }

  getCbxCentro(IdEmpresa: String): Observable<any> {
    return this.http.get(`${this.apiUrl}get/QuestionData?Quest=CbxCentro&IdEmpresa=${IdEmpresa}`);
  }

  getCbxRelacionProf(IdEmpresa: String): Observable<any> {
    return this.http.get(`${this.apiUrl}get/QuestionData?Quest=CbxRelacionProf&IdEmpresa=${IdEmpresa}`);
  }

  async EstadoDenuncia(csv:string): Promise<any> {
    return this.http.get(`${this.apiUrl}get/QuestionStatus?${csv}`).toPromise();
  }

  async addDenuncia(denuncia: Denuncia, formDataList: any[], file_coments: any[], csv: string = undefined): Promise<any> {
    try {
      // Realiza la solicitud inicial para crear la denuncia
      const response = await this.http
        .post<any>(`${this.apiUrl}post/QuestionData?hash_csv=${csv === undefined ? "newDemand" : csv}&coment=newDemand`, denuncia, this.httpHeader)
        .toPromise();

      // Itera sobre los archivos para enviarlos uno a uno de manera secuencial
      for (let index = 0; index < formDataList.length; index++) {
        const formData = formDataList[index];
        const coment = file_coments[index];
        // Enviar archivo con await para esperar que cada uno termine antes de enviar el siguiente
        await this.http.post(`${this.apiUrl}post/QuestionData?hash_csv=${csv === undefined ? response.hash : csv}&coment=${coment}`, formData, {
          reportProgress: true,
          observe: 'events'
        }).toPromise().then(event => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress = Math.round(100 * (event.loaded / event.total));
            console.warn(this.uploadProgress, response);
          }
        });
      }
      // Devuelve la respuesta final
      return response;
    } catch (operation) {
      return this.handleError(operation);
    }
  }

  async setDenunciaEstado(IdDenuncia: number, IdEstado: number){
    const response = await this.http
        .post<any>(`${this.apiUrl}post/QuestionSetStatus?IdDenuncia=${IdDenuncia}&IdEstado=${IdEstado}`, this.httpHeader).toPromise();
    return response;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
