import { Component, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertController, IonList, IonRouterOutlet, LoadingController, ModalController, ToastController, Config } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

import { ConfiguracionService } from '../../services/config.service';
import { UserData } from '../../providers/user-data';
import { Denuncia } from '../../models/denunciaModel';

@Component({
  selector: 'page-seguimiento',
  templateUrl: 'seguimiento.html',
  styleUrls: ['./seguimiento.scss'],
})
export class SeguimientoPage implements OnInit {
  @ViewChild('seguimientoList', { static: true }) seguimientoList: IonList;

  ios: boolean;
  regForm: FormGroup;
  csv: string;
  idempresa: number;
  groups: any = [];
  fileName: any = [];
  formData: any = [];
  uploaderror: boolean = false;
  isLoading: boolean = false;
  altimg: string;
  files: any[] = [];
  file_coments: any[] = [];

  constructor(
    public alertCtrl: AlertController, public confData: ConfiguracionService,
    public loadingCtrl: LoadingController, public modalCtrl: ModalController,
    public router: Router, private route: ActivatedRoute, public routerOutlet: IonRouterOutlet,
    public toastCtrl: ToastController, public user: UserData, public config: Config,
    private storage: Storage, private configuracionService: ConfiguracionService,
    private formBuilder: FormBuilder
  ) {
    this.regForm = this.formBuilder.group({});
    this.uploaderror = false;
  }

  ngOnInit() {
    this.ios = this.config.get('mode') === 'ios';
    this.updateSeguimiento();
  }

  async ionViewDidEnter() {
    const config = await this.storage.get("config");
    this.altimg = config.descripcion;
    this.idempresa = config.idempresa;
  }

  getLastState(groups: any[]): number {
    let lastStateId: number = -1;
    groups.forEach(group => {
      let maxTime: Date = new Date(group.time);
      group.sessions.forEach(session => {
        let sessionTime: Date = new Date(`${group.time}T${session.timeChange}`);
        if (sessionTime > maxTime) {
          maxTime = sessionTime;
          lastStateId = session.id;
        }
      });
    });
    return lastStateId;
  }

  onChangeComent(index: number, event: any) {
    this.file_coments[index] = event.detail.value;
  }
  onFileSelected(event: any) {
    const maxTotalFileSizeInBytes = 20 * 1024 * 1024;
    const selectedFiles = Array.from(event.target.files) as File[];

    // Añadir solo archivos válidos sin sobrescribir los comentarios existentes
    selectedFiles.forEach(file => {
      if (file) {
        // Verificar si el archivo ya existe en la lista
        const existingFileIndex = this.files.findIndex(f => f.name === file.name);

        if (existingFileIndex === -1) {
          // Solo agregar el archivo si no está ya presente
          this.files.push(file);
          const controlName = "file_coment_" + (this.files.length - 1);
          this.regForm.addControl(controlName, this.formBuilder.control(null));
        }
      }
    });

    // Recalcular el tamaño total
    let totalFileSize = this.files.reduce((acc, file) => acc + (file ? file.size : 0), 0);
    const fileSizeInfo = document.querySelector('.file-size-info') as HTMLElement;

    if (totalFileSize > maxTotalFileSizeInBytes) {
      fileSizeInfo.textContent = 'El tamaño total de los archivos excede el límite permitido (20 MB).';
      fileSizeInfo.style.color = 'red';
    } else {
      fileSizeInfo.textContent = 'Tamaño máximo total: 20 MB';
      fileSizeInfo.style.color = '#757575';
    }

    // Preservar los comentarios anteriores
    for (let index = 0; index < this.files.length; index++) {
      const file: File = this.files[index];
      const control = this.regForm.get("file_coment_" + index);

      if (control) {
        // Mantener el comentario si ya existía
        this.file_coments[index] = this.file_coments[index] || control.value;
      }

      if (file) {
        this.fileName[index] = file.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const archivoModificado = new File([file], this.fileName[index], {
            type: file.type,
            lastModified: file.lastModified
        });
        this.formData[index] = new FormData();
        this.formData[index].append("thumbnail", archivoModificado);
      }
    }
  }

  eliminarFile(index: number) {
    if (index >= 0 && index < this.files.length) {
      this.files.splice(index, 1);
      this.file_coments.splice(index, 1);

      this.file_coments = this.file_coments.filter((comment, i) => i < this.files.length);

      const fileSizeInfo = document.querySelector('.file-size-info') as HTMLElement;

      let totalFileSize = this.files.reduce((acc, file) => {
        return acc + (file && file.size ? file.size : 0);
      }, 0);

      if (totalFileSize > 20 * 1024 * 1024) {
        fileSizeInfo.textContent = 'El tamaño total de los archivos excede el límite permitido (20 MB).';
        fileSizeInfo.style.color = 'red';
      } else {
        fileSizeInfo.textContent = 'Tamaño máximo total: 20 MB';
        fileSizeInfo.style.color = '#757575';
      }

      this.regForm.patchValue(this.regForm.value);
    }
  }

  triggerFileInput() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) { fileInput.click(); }
  }

  async onNewDocument() {
    const config = await this.storage.get("config");
    const denuncia = new Denuncia({});
    this.isLoading = true;
    this.uploaderror = false;
    denuncia.idempresa = config.idempresa;
    const responseDenuncia = await this.configuracionService
      .addDenuncia(denuncia, this.formData, this.file_coments, this.csv)
      .then((data) => { return data; });
    if (responseDenuncia.hash) {
      const response = await this.configuracionService
        .setDenunciaEstado(responseDenuncia.IdDenuncia, 3)
        .then((data) => { return data; })
        .catch((data) => { return data; });
      if (response.status == "OK") {
        location.reload();
      } else {
        const responseStatus = await this.configuracionService.EstadoDenuncia(responseDenuncia.csv)
          .then((data) => { return this.getLastState(data); });
        if (responseStatus === 3) {
          location.reload();
        } else {
          this.uploaderror = true;
          this.isLoading = false;
        }
      }
    } else {
      this.uploaderror = true;
      this.isLoading = false;
    }
  }

  onNewInput() {
    this.files.push(null);
  }

  async updateSeguimiento() {
    const loading = await this.loadingCtrl.create({
      message: 'Cargando datos...',
    });
    await loading.present();

    if (this.seguimientoList) { this.seguimientoList.closeSlidingItems(); }
    this.csv = this.route.snapshot.params['csv'];
    if (!this.csv) {
      this.groups = null;
      await loading.dismiss();
      return;
    }

    try {
      this.groups = await this.confData.EstadoDenuncia(this.csv);
      if (this.groups && this.groups.length > 0 && this.groups[0].empresa != this.idempresa) {
        this.groups = undefined;
        this.csv = this.csv + ' no encontrada';
      }
    } catch (error) {
      console.error('Error al obtener datos de la empresa:', error);
      this.groups = undefined;
      this.csv = 'Error al cargar datos de la empresa';
    }

    await loading.dismiss();
  }
}
