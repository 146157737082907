<ion-content fullscreen="true">
  <div id="elemPDF">
    <div class="contenido-pdf ion-text-center">
      <img [src]="logoimg" [alt]="empresa">
      <h1>Informe de Denuncia</h1>
      <p>Centro: {{ centro }}</p>
      <p>Fecha: {{ fechahora() }}</p>
      <img [src]="qrCodeImageUrl" *ngIf="qrCodeImageUrl" alt="QR Code">
      <p>Código de referencia: {{ this.hashDenuncia.split('-')[0] }}</p>
      <div *ngIf="!denuncia.primeravez">
        <h3>Denuncia Anterior</h3>
        <p>Código de referencia: {{ denuncia?.csvinterno }}</p>
        <img  [src]="qrCodeImageUrlAnt" alt="QR Code Denuncia anterior">
      </div>
      <div *ngIf="denuncia.denunciaexterna">
        <h3>Denuncia Anterior Externa</h3>
        <p>Código de referencia: {{ denuncia?.csvexterno }}</p>
      </div>
    </div>
  </div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-button (click)="generateReport()">
      <ion-icon name="download"></ion-icon> Generar PDF
    </ion-button>
  </ion-fab>
</ion-content>
